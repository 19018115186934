import _ from "lodash";
import moment from "moment";
import AppUtil from "../../../utils/AppUtil";

//api dùng cho booking view
export const getConfigBranchByDate = async (branchId, date, configAppointment, setState) => {
    const resConfig = await AppUtil.getApi("/booking/get-config-branch-by-date", { branchId: branchId, date: date });
    if (_.get(resConfig, "data.success")) {
        const configBooking = _.get(resConfig, "data.result");
        configAppointment.multiSetting = configBooking.MULTITASK;
        configAppointment.confirmBookingOnline = configBooking.CONFIRM_BOOKING_ONLINE;
        configAppointment.minuteOverlap = configBooking.MULTITASK ? 0 : parseInt(configBooking.MINUTE_OVERLAP);
        configAppointment.printBillSlip = configBooking.PRINT_BILL_SLIP;
        let extraEnd = ''
        let extraStart = ''
        let endHour = moment(configBooking.endTime, "HH:mm")
        let startHour = moment(configBooking.startTime, "HH:mm")

        if (parseFloat(configBooking.workingOverTime)) {
            extraEnd = _.cloneDeep(endHour).add(parseFloat(configBooking.workingOverTime), "minutes").format("HH:mm");
            extraStart = _.cloneDeep(startHour).subtract(parseFloat(configBooking.workingOverTime), "minutes").format("HH:mm");
        }

        configAppointment.startHour = startHour.format("HH:mm");
        configAppointment.endHour = endHour.format("HH:mm");
        if (extraEnd && extraEnd > configBooking.endTime) {
            configAppointment.extraEndHour = extraEnd
        }
        if (extraStart && extraStart < configBooking.startTime) {
            configAppointment.extraStartHour = extraStart
        }
        setState({
            ...configAppointment,
            loading: false
        })
    }
}

export const getConfig = async (branchId, configAppointment, setState) => {
    const resConfig = await AppUtil.getApi("/branch/get-config", { branchId: branchId });
    if (_.get(resConfig, "data.success")) {
        const configBooking = _.get(resConfig, "data.result");
        configAppointment.multiSetting = configBooking.MULTITASK;
        configAppointment.minuteOverlap = configBooking.MULTITASK ? 0 : parseInt(configBooking.MINUTE_OVERLAP);
        configAppointment.printBillSlip = configBooking.PRINT_BILL_SLIP;
        configAppointment.confirmBookingOnline = configBooking.CONFIRM_BOOKING_ONLINE;
        setState && setState({
            ...configAppointment,
        })
    }
}
export const getConfigByDate = async (branchId, date, configAppointment, setState) => {
    const resConfig = await AppUtil.getApi("/booking/get-config-branch-by-date", { branchId: branchId, date: date });
    if (_.get(resConfig, "data.success")) {
        const configBooking = _.get(resConfig, "data.result");
        let extraEnd = ''
        let extraStart = ''
        let endHour = moment(configBooking.endTime, "HH:mm")
        let startHour = moment(configBooking.startTime, "HH:mm")

        if (parseFloat(configBooking.workingOverTime)) {
            extraEnd = _.cloneDeep(endHour).add(parseFloat(configBooking.workingOverTime), "minutes").format("HH:mm");
            extraStart = _.cloneDeep(startHour).subtract(parseFloat(configBooking.workingOverTime), "minutes").format("HH:mm");
        }
        configAppointment.startHour = startHour.format("HH:mm");
        configAppointment.endHour = endHour.format("HH:mm");
        if (extraEnd && extraEnd > configBooking.endTime) {
            configAppointment.extraEndHour = extraEnd
        }
        if (extraStart && extraStart < configBooking.startTime) {
            configAppointment.extraStartHour = extraStart
        }
        configAppointment.isHoliday = configBooking.isHoliday;
        configAppointment.holiday = configBooking.holiday;
        configAppointment.confirmBookingOnline = configBooking.CONFIRM_BOOKING_ONLINE;
        if (date) {
            configAppointment.dateSelected = moment(date, "YYYY-MM-DD").toDate();
        }
        setState && setState({
            ...configAppointment,
        })
    }
}
export const getTechnicianByDate = async (branchId, date, objState, setState, callback) => {
    const res = await AppUtil.getApi("/technician/schedule-technician-date", { branchId: branchId, date: date });
    if (_.get(res, "data.success")) {
        setState && setState({
            technicianSchedule: _.get(res, "data.result")
        })
        callback && callback()
    }
}
export const getDataBooking = async (branchId, date, objState, setState, scrollToCurrentHour = true, callback) => {
    const objResult = {
        loading: false,
        scrollToCurrentHour
    }
    const res = await AppUtil.getApi("/booking/web-schedule-booking", { branchId: branchId, date: date });
    let bookings = [];
    let guests = [];
    if (_.get(res, "data.success")) {
        const dataBooking = _.get(res, "data.result");
        bookings = dataBooking.bookings
        guests = _.filter(dataBooking.guests, (x) => x.status !== "Cancel" && x.status !== "NoShow");
        const flatAllData = handleDataBooking(bookings)
        objResult.bookings = bookings
        objResult.guests = guests
        objResult.flatAllData = flatAllData;
        objResult.bookingSchedule = _.filter(flatAllData, (x) => x.Type === "services" && x.TechnicianId);
    } else {
        AppUtil.ToastError(_.get(res, "data.message"))
    }

    if (setState) {
        setState(objResult);
    }
    callback && callback()
    return objResult
};

export const getDataClipboard = async (branchId, date, objState, setState, scrollToCurrentHour = true, callback) => {
    const resClipboard = await AppUtil.getApi("/booking/clipboard-booking", {
        branchId: branchId,
        // scheduleDate: date
    });
    let waitingList = []
    if (_.get(resClipboard, "data.success")) {
        const dataClipboard = _.get(resClipboard, "data.result");
        waitingList = handleDataClipboard(dataClipboard.guests, dataClipboard.bookings);
    }
    const objResult = {
        waitingList: waitingList,
        loading: false,
        scrollToCurrentHour
    }
    if (setState) {
        setState(objResult);
    }
    callback && callback()

    return objResult
};

export const getData = async (branchId, date, objState, setState, scrollToCurrentHour = true) => {
    //
    // const res = await AppUtil.getApi("/booking/web-schedule-booking", {branchId: branchId, date: date});
    // const resClipboard = await AppUtil.getApi("/booking/clipboard-booking", {
    //     branchId: branchId,
    //     // scheduleDate: date
    // });
    // let bookings = [];
    // let guests = [];
    // if (_.get(res, "data.success")) {
    //     const dataBooking = _.get(res, "data.result");
    //     bookings = dataBooking.bookings
    //     guests = _.filter(dataBooking.guests, (x) => x.status !== "Cancel" && x.status !== "NoShow");
    //     const flatAllData = handleDataBooking(bookings)
    //     objState.flatAllData = flatAllData;
    //     objState.bookingSchedule = _.filter(flatAllData, (x) => x.Type === "services" && x.TechnicianId);
    // } else {
    //     AppUtil.ToastError(_.get(res, "data.message"))
    // }
    // if (_.get(resClipboard, "data.success")) {
    //     const dataClipboard = _.get(resClipboard, "data.result");
    //     objState.waitingList = handleDataClipboard(dataClipboard.guests, dataClipboard.bookings);
    // }
    // const objResult = {
    //     ...objState,
    //     bookings,
    //     loading: false,
    //     guests: guests,
    //     scrollToCurrentHour
    // }
    // if (setState) {
    //     setState(objResult);
    // }
    // return objResult
};

export const handleDataClipboard = (guests, bookings) => {
    return _.map(guests, (guest) => {
        const booking = _.find(bookings, (x) => x.id === guest.bookingId);
        return {
            ...guest,
            guestId: null,
            expanded: true,
            customerName: booking.customerName,
            createdTime: booking.createdTime,
            startTime: booking.startTime,
            bookingCode: booking.code,
            phone: _.get(booking.customer, "phone")
        };
    });
};

export const handleDataBooking = (bookings, date = null) => {
    const flatAllData = [];
    _.each(bookings, (booking) => {
        const objBooking = {};
        objBooking.Id = booking.id;
        objBooking.BookingCode = booking.code;
        objBooking.BookingStatus = booking.status;
        objBooking.BookingItemId = null;
        objBooking.ServiceId = null;
        objBooking.BookingId = null;
        objBooking.TechnicianId = null;
        objBooking.StartTime = booking.startTime;
        objBooking.EndTime = booking.endTime;
        objBooking.Status = booking.status;
        objBooking.Subject = `${booking.code} - ${booking.customerName} ${booking.phone ? "- " + booking.phone : ""}`;
        objBooking.Type = "booking";
        objBooking.Expanded = true;
        objBooking.CustomerName = booking.customerName;
        objBooking.CustomerId = booking.customerId;
        objBooking.Phone = _.get(booking.customer, "phone");
        objBooking.CustomerNotes = _.get(booking.customer, "notes");
        objBooking.PaymentMethod = booking.paymentMethod;
        // objBooking.Sex = null;
        objBooking.Block = booking.block;
        objBooking.Color = booking.color;
        objBooking.TypeBooking = booking.type;
        objBooking.Description = booking.note;
        objBooking.CreatedAt = booking.createdTime;
        objBooking.Confirm = booking.confirm;
        objBooking.Remind = booking.remind;
        objBooking.AlreadyTip = booking.alreadyTip
        objBooking.CustomerBirthday = _.get(booking, "customer.birthDay")
        flatAllData.push(objBooking);
        _.each(booking.guests, (guest) => {
            // if ([BookingStatusContant.STATUS_NOSHOW, BookingStatusContant.STATUS_CANCEL].indexOf(guest.status) < 0) {
            _.each(guest.segments, (seg) => {
                if (seg.type !== "extra") {
                    const obj = {};
                    obj.GuestId = guest.id;
                    obj.Id = `${booking.id}_${guest.id}_${seg.serviceId}`;
                    obj.BillId = guest.billId;
                    obj.BookingCode = booking.code;
                    obj.BookingItemId = seg.id;
                    obj.BookingStatus = booking.status;
                    obj.ServiceId = seg.serviceId;
                    obj.ServiceName = seg.serviceName;
                    obj.BookingId = booking.id;
                    obj.TechnicianId = seg.technicianId;
                    obj.StartTime = seg.startTime;
                    obj.EndTime = seg.endTime;
                    obj.Status = guest.status;
                    obj.Subject = `${booking.code} - ${seg.serviceName}`;
                    obj.Type = "services";
                    obj.ServiceTime = seg.duration;
                    obj.ServiceClean = seg.cleaning;
                    obj.ServicePrice = seg.price;
                    obj.CustomerName = guest.name;
                    obj.CustomerId = booking.customerId;
                    obj.Phone = _.get(booking.customer, "phone");
                    obj.CustomerNotes = _.get(booking.customer, "notes");
                    obj.ServiceCode = "";
                    obj.PaymentMethod = booking.paymentMethod;
                    // obj.Sex = guest.gender;
                    obj.Block = guest.block;
                    obj.Color = booking.color;
                    obj.TypeBooking = booking.type;
                    obj.Description = booking.note;
                    obj.CreatedAt = booking.createdTime;
                    obj.Confirm = booking.confirm;
                    obj.Remind = booking.remind;
                    obj.AlreadyTip = guest.alreadyTip;
                    obj.BillBookingMappings = guest.billBookingMappings
                    obj.Gender = seg.gender;
                    obj.DurationChange = _.get(seg, 'durationChange');
                    obj.PriceChange = seg.priceChange;
                    obj.IsCustomerNew = booking.isCustomerNew;
                    obj.CustomerBirthday = _.get(booking, "customer.birthDay")
                    obj.Date = date;
                    flatAllData.push(obj);
                }

            });
            // }

        });
    });
    return flatAllData;
};
const useGetConfigScheduler = ({ date, branchId, reload, seconds }) => {
    // const [state, setState] = useMergeState({
    //     bookings: [],
    //     configAppointment: {
    //         technicianSchedule: [],
    //         bookingSchedule: [],
    //         startHour: "08:00",
    //         endHour: "17:00",
    //         waitingList: [],
    //         dateSelected: new Date(),
    //         flatAllData: [],
    //         reloadWaiting: false,
    //         technicianSkills: [],
    //         readOnly: false,
    //         // scrollToCurrentHour: combo.scrollToCurrentHour,
    //         multiSetting: false,
    //         extraEndHour: null,
    //         minuteOverlap: 0,
    //         printBillSlip: false
    //     },
    //     technicianWorking: [],
    //     loading: true,
    //     guests: []
    // });
    // useEffect(() => {
    //     branchId && getConfig(branchId, state.configAppointment, setState);
    // }, [branchId]);
    //
    // useEffect(() => {
    //     branchId && getData(branchId, date, state.configAppointment, setState);
    // }, [date, branchId, reload, seconds]);
    //
    //
    // return {...state};
};
export default useGetConfigScheduler;


