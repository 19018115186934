import { Container } from "@idtek/component/lib";
import { useMergeState } from "@idtek/component/lib/hook/IDHook";
import Icon from "@idtek/component/lib/icon/Icon";
import Modal from "@idtek/component/lib/modal/Modal";
import _ from "lodash";
import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import AppStore from "../../../store";
import SchedulerComponent from "../component/SchedulerComponent";
import { AppointmentProvider } from "../context/AppointmentContext";
import PaymentContainer from "./PaymentContainer";
import PaymentDetailContainer from "./PaymentDetailContainer";
import {
    getConfigByDate,
    getDataBooking,
    getDataClipboard,
    getTechnicianByDate,
    handleDataBooking,
    handleDataClipboard
} from "../hook/useGetConfigScheduler";
import BookingCreateView from "./BookingCreateView";
import BookingUpdateView from "./BookingUpdateView";
import "../style/appointment-style.scss";
import "../style/index.scss";
import "../style/dashboard-v2.scss";
import "../style/dashboard.scss";
import BasePermission from "../../../common/baseView/BasePermission";
import { allRights } from "../../../utils/rightUtils";
import NotPermission from "../../../pages/NotPermission";
import { t } from "@idtek/component/lib/language";
import PaymentWrapView from "./PaymentWrapView";
import PaymentConstant from "../constant/PaymentConstant";
import HolidayOffView from "./HolidayOffView";
import FilterHeaderLeft from "../component/FilterHeaderLeft";
import FilterHeaderRight from "../component/FilterHeaderRight";
import AppUtil from "../../../utils/AppUtil";
import Overlay from "../../../common/component/Overlay";
import useInterval from "../hook/useInterval";
import { Tooltip } from '@idtek/component';
import Loading from "../../../pages/Loading";
import { BOOKING_TYPE } from "../../posTransaction/constant/BookingConstant";
import GridHistoryBooking from './../../../common/history/GridHistoryBooking';
import { useHistory, useLocation } from "react-router-dom";

const SchedulerView = () => {
    //variable
    const containerRef = useRef(null),
        filterHeaderRef = useRef(null),
        schedulerRef = useRef(null),
        modalRef = useRef(null),
        modalRefUpdate = useRef(null),
        modalCloneRef = useRef(null),
        modalPayment = useRef(null),
        modalRefHistory = useRef(null),
        refOverlay = useRef(null),
        countTimeLive = useRef(0);

    const branchId = _.get(AppStore.getState(), `root.branch.id`, null);
    const preBranchId = useRef(null)
    const history = useHistory();
    const [count, setCount] = useState(0)
    const [resetBooking, setResetBooking] = useState(0)
    const [resetClipboard, setResetClipboard] = useState(0)
    const [resetTechnician, setResetTechnician] = useState(0)

    const search = useLocation().search;
    const dateParam = new URLSearchParams(search).get('date');

    const [state, setState] = useMergeState({
        date: dateParam ?? moment().format("YYYY-MM-DD"),
        scrollToCurrentHour: true,
        bookings: [],
        configAppointment: {
            // technicianSchedule: [],
            // bookingSchedule: [],
            // waitingList: [],
            // flatAllData: [],

            startHour: "08:00",
            endHour: "17:00",
            reloadWaiting: false,
            readOnly: false,
            multiSetting: true,
            confirmBookingOnline: false,
            extraEndHour: null,
            minuteOverlap: 0,
            printBillSlip: false,
        },
        dateSelected: dateParam ? new Date(dateParam) : new Date(),
        technicianWorking: [],
        loading: true,
        guests: [],
        firstLoad: true,
        technicianSchedule: [],
        bookingSchedule: [],
        waitingList: [],
        flatAllData: [],
    });

    useEffect(async () => {
        if (branchId) {
            openOverlay()
            await getAllConfig(branchId, state.date, state, setState, state.scrollToCurrentHour)
        }
    }, [branchId, state.date])

    useEffect(async () => {
        if (count > 0) {
            openOverlay()
            toggleInterval()
            await getAllConfig(branchId, state.date, state, setState, state.scrollToCurrentHour, toggleInterval)

        }
    }, [count])

    useEffect(() => {
        if (resetBooking > 0) {
            toggleBooking()
            const func = getDataBooking(branchId, date, state, setState, false, toggleBooking);
        }
    }, [resetBooking])

    useEffect(() => {
        if (resetClipboard > 0) {
            toggleClipboard()
            const func = getDataClipboard(branchId, date, state, setState, false, toggleClipboard);
        }
    }, [resetClipboard])

    useEffect(() => {
        if (resetTechnician > 0) {
            toggleTechnician()
            const func = getTechnicianByDate(branchId, date, state, setState, false, toggleTechnician);
        }
    }, [resetTechnician])

    const getAllConfig = (branchId, date, objState, setState, scrollToCurrentHour = true, callback) => {
        const fetches = []
        const url1 = AppUtil.getApi("/technician/schedule-technician-date", { branchId: branchId, date: date })
        const url2 = AppUtil.getApi("/booking/get-config-branch-by-date", { branchId: branchId, date: date })
        const url3 = AppUtil.getApi("/booking/web-schedule-booking", { branchId: branchId, date: date })
        const url4 = AppUtil.getApi("/booking/clipboard-booking", { branchId: branchId })

        fetches.push(url1, url2, url3, url4)
        if (branchId !== preBranchId.current) {
            const url5 = AppUtil.getApi("/branch/get-config", { branchId: branchId })
            fetches.push(url5)
            preBranchId.current = branchId
        }
        AppUtil.Axios.all(fetches)
            .then(async ([res1, res2, res3, res4, res5]) => {
                if (_.get(res1, "data.success")) {
                    //config1 techSchedule
                    const { configAppointment } = objState
                    const technicianSchedule = _.get(res1, "data.result");

                    //config2 config-date
                    const config2 = _.get(res2, "data.result");
                    let extraEnd = ''
                    let extraStart = ''
                    let endHour = moment(config2.endTime, "HH:mm")
                    let startHour = moment(config2.startTime, "HH:mm")

                    if (parseFloat(config2.workingOverTime)) {
                        extraEnd = _.cloneDeep(endHour).add(parseFloat(config2.workingOverTime), "minutes").format("HH:mm");
                        extraStart = _.cloneDeep(startHour).subtract(parseFloat(config2.workingOverTime), "minutes").format("HH:mm");
                    }
                    configAppointment.startHour = startHour.format("HH:mm");
                    configAppointment.endHour = endHour.format("HH:mm");
                    if (extraEnd && extraEnd > config2.endTime) {
                        configAppointment.extraEndHour = extraEnd
                    }
                    if (extraStart && extraStart < config2.startTime) {
                        configAppointment.extraStartHour = extraStart
                    }
                    configAppointment.isHoliday = config2.isHoliday;
                    configAppointment.holiday = config2.holiday;
                    // if (date) {
                    //     configAppointment.dateSelected = moment(date, "YYYY-MM-DD").toDate();
                    // }

                    //config3 booking schedule
                    const config3 = _.get(res3, "data.result");
                    const bookings = config3.bookings
                    const guests = _.filter(config3.guests, (x) => x.status !== "Cancel" && x.status !== "NoShow");
                    const flatAllData = handleDataBooking(bookings, date)
                    const bookingSchedule = _.filter(flatAllData, (x) => x.Type === "services" && x.TechnicianId);
                    //config 4 clipboard

                    const config4 = _.get(res4, "data.result");
                    const waitingList = config4 ? handleDataClipboard(config4.guests, config4.bookings) : objState.waitingList

                    //config 5 branch
                    const config5 = _.get(res5, "data.result");
                    if (config5) {
                        configAppointment.multiSetting = config5.MULTITASK;
                        configAppointment.minuteOverlap = config5.MULTITASK ? 0 : parseInt(config5.MINUTE_OVERLAP);
                        configAppointment.printBillSlip = config5.PRINT_BILL_SLIP;
                        configAppointment.confirmBookingOnline = config5.CONFIRM_BOOKING_ONLINE;
                    }


                    const objResult = {
                        bookings,
                        configAppointment: {
                            ...configAppointment
                        },
                        loading: false,
                        guests: guests,
                        scrollToCurrentHour,
                        firstLoad: false,
                        dateSelected: moment(date, "YYYY-MM-DD").toDate(),
                        technicianSchedule,
                        bookingSchedule,
                        waitingList,
                        flatAllData
                    }
                    // if (objResult.scrollToCurrentHour) {
                    //     objResult.dateSelected = _.clone(moment(date, "YYYY-MM-DD").toDate())
                    // }
                    setState && setState(objResult)
                    callback && callback()

                }
            }).catch((error) => {
                AppUtil.ToastApiError();
            });
    }

    const [toggleBooking, runningBooking] = useInterval(() => {
        if (branchId && moment().format("YYYY-MM-DD") <= moment(date).format("YYYY-MM-DD")) {
            const func = getDataBooking(branchId, date, state.configAppointment, setState, false);
        }
    }, 60000);

    const [toggleClipboard, runningClipboard] = useInterval(() => {
        if (branchId && moment().format("YYYY-MM-DD") <= moment(date).format("YYYY-MM-DD")) {
            const func = getDataClipboard(branchId, date, state.configAppointment, setState, false);
        }
    }, 30000);

    const [toggleConfig, runningConfig] = useInterval(() => {
        if (branchId && moment().format("YYYY-MM-DD") <= moment(date).format("YYYY-MM-DD")) {
            const func = getConfigByDate(branchId, date, state.configAppointment, setState, false);
        }
    }, 5 * 60000);

    const [toggleTechnician, runningTechnician] = useInterval(() => {
        if (branchId && moment().format("YYYY-MM-DD") <= moment(date).format("YYYY-MM-DD")) {
            const func = getTechnicianByDate(branchId, date, state.configAppointment, setState, false);
        }
    }, 60000);

    useInterval(() => {
        countTimeLive.current = countTimeLive.current + 1;
    }, 1000)

    const toggleInterval = () => {
        toggleBooking()
        toggleClipboard()
        toggleConfig()
        toggleTechnician()
    }

    const preventInterval = () => {
        if (runningBooking) toggleBooking()
        if (runningClipboard) toggleClipboard()
        if (runningConfig) toggleConfig()
        if (runningTechnician) toggleTechnician()
    }

    const continueInterval = () => {
        if (!runningBooking) toggleBooking()
        if (!runningClipboard) toggleClipboard()
        if (!runningConfig) toggleConfig()
        if (!runningTechnician) toggleTechnician()
    }


    const onCheckBeforeViewReset = () => {
        if (countTimeLive.current > 7200) {
            window.location.reload();
        }
        countTimeLive.current = 0;
    }

    const onResetDataBooking = () => {
        setResetBooking(pre => pre + 1)
    }

    const onResetDataClipboard = () => {
        setResetClipboard(pre => pre + 1)
    }

    const onResetDataTechnician = () => {
        setResetTechnician(pre => pre + 1)
    }
    //func
    const onReloadData = () => {
        setState({
            scrollToCurrentHour: false,
            loading: true
        });
        setCount(pre => pre + 1)
    };

    const onChangeFilter = ({ dateChange, scrollToCurrentHour }) => {
        if (dateChange === date) {
            setState({ scrollToCurrentHour: scrollToCurrentHour, loading: true })
            setCount(pre => pre + 1)
        } else {
            if (!moment(dateChange).isSame(moment(), 'day')) {
                history.push({
                    pathname: "/appointment",
                    search: `?date=${moment(dateChange).format("YYYY-MM-DD")}`
                })
                window.location.reload();
            } else {
                history.push({
                    pathname: "/appointment",
                })
                setState({
                    date: dateChange,
                    scrollToCurrentHour: scrollToCurrentHour,
                    loading: true
                });
            }

        }
    };

    const onUpdateBookingFromSearch = (booking) => {
        const { id, guests, code, statusPriority, statusCustomer } = booking
        const guestIds = _.map(guests, x => x.id)
        onUpdateBooking(id, guestIds, code, statusPriority)
    }

    const onUpdateBooking = (bookingId, guestId, code, status, isDetail = false, type) => {
        let title = t("SchedulerView-UPDATE_BOOKING");
        if (isDetail) {
            title = t("SchedulerView-DETAIL_BOOKING");
        }
        let unsetMinDate = false;
        if (type === "clipboard") {
            unsetMinDate = true;
        }
        modalRefUpdate.current &&
            modalRefUpdate.current.onOpen(
                {
                    component: <BookingUpdateView
                        onClose={() => {
                            modalRefUpdate.current && modalRefUpdate.current.onClose();
                        }}
                        onReloadData={() => {
                            onResetDataBooking()
                            if (type === 'clipboard') {
                                onResetDataClipboard()
                            }
                        }}
                        isDetail={isDetail}
                        bookingId={bookingId}
                        unsetMinDate={unsetMinDate}
                        bookingStatus={status}
                        onCloneBooking={onCloneBooking}
                        guestId={guestId}
                        onDetailAddCart={onDetailAddCart}
                        onAddCart={onAddCart}
                        onProceedPayment={onProceedPayment}
                        onCheckReloadPage={() => onCheckBeforeViewReset()}
                    />,
                    title: <span className={"header-title-modal"}>
                        <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                            <Icon type="form"
                                style={{ fontSize: 16, display: "flex", color: "var(--main-color-blue-ocean)" }} />
                            {code && status ? <>
                                <span className={'three-dot-minimize'} style={{
                                    fontSize: 14,
                                    display: "flex",
                                    marginLeft: 7,
                                    marginRight: 7
                                }}>
                                    {title + " " + code + " - "}
                                </span>
                                <span style={{ width: 'unset' }}
                                    className={"status-booking " + status.replace(/\s+/g, "")}>{t("SchedulerView-" + status.toUpperCase())}
                                </span>
                            </>
                                :
                                <span style={{ fontSize: 15, display: "flex", marginLeft: 7 }}>{title}</span>}
                        </span>
                    </span>,
                    extraButtons: <Tooltip title={t("BaseListView-HISTORY")}>
                        <Icon type="history"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                onHistoryBooking(code, bookingId)
                            }} /></Tooltip>,
                    width: "1000px"
                }
            );
    };

    const onHistoryBooking = (code, bookingId) => {
        const titleModal = `${t("BaseListView-HISTORY")} ${code}`;
        // const initialValues = {
        //     id: bookingId
        // }
        modalRefHistory.current && modalRefHistory.current.onOpen(
            // <BookingHistoryTemplate
            //     initialValues={initialValues}
            //     onClose={() => {
            //         modalRefHistory.current && modalRefHistory.current.onClose();
            //     }} />,
            // titleModal,
            // `1000px`

            <GridHistoryBooking
                id={bookingId}
                type={BOOKING_TYPE}
                onClose={() => {
                    modalRefHistory.current && modalRefHistory.current.onClose();
                }} />,
            titleModal,
            '1200px'
        );
    }

    const onCloneBooking = (booking) => {
        modalRef.current && modalRef.current.onClose();
        setTimeout(() => {
            modalCloneRef.current &&
                modalCloneRef.current.onOpen(
                    <Suspense fallback={<Loading style={{ hieght: 300 }} />}>
                        <BookingCreateView
                            cloneView={true}
                            onClose={() => {
                                modalCloneRef.current && modalCloneRef.current.onClose();
                            }}
                            onReloadData={() => onResetDataBooking()}
                        />
                    </Suspense>,
                    <span className={"header-title-modal"}>
                        <span style={{ position: "relative", marginRight: 7, display: "flex", alignItems: "center" }}>
                            <Icon type="form"
                                style={{
                                    fontSize: 16,
                                    display: "flex",
                                    color: "var(--main-color-blue-ocean)",
                                }} />
                            <span style={{
                                fontSize: 15,
                                display: "flex",
                                marginLeft: 7,
                            }}>{`${t("SchedulerView-CLONE_BOOKING_FROM")} ${booking.code}`}</span>
                        </span>
                    </span>,
                    "1000px"
                );
        }, 200);
    };

    const onCreateBooking = (technicianId, startTime) => {
        modalRef.current &&
            modalRef.current.onOpen(
                <BookingCreateView
                    onClose={() => {
                        modalRef.current && modalRef.current.onClose();
                    }}
                    onReloadData={() => onResetDataBooking()}
                    onCheckReloadPage={() => onCheckBeforeViewReset()}
                // startTime={moment(startTime)}
                // technicianId={technicianId}
                />,

                <span className={"header-title-modal"}>
                    {AppUtil.renderTitleForm(t("SchedulerView-CREATE_BOOKING"))}
                </span>,
                "1000px", null,
            );
    };

    const onUpdateBookingNew = () => {
    };

    const onAddCart = (bookingId, guestId) => {
        modalPayment.current &&
            modalPayment.current.onOpen(
                <PaymentWrapView
                    onClose={() => {
                        modalPayment.current && modalPayment.current.onClose();
                    }}
                    onReloadData={() => onResetDataBooking()}
                    bookingId={bookingId}
                    guestId={guestId}
                    branchId={branchId}
                    step={PaymentConstant.STEP_2_CART_BILL}
                />,
                <span className={"header-title-modal"} style={{ display: 'flex', alignItems: 'center' }}>
                    <i class="fa fa-money" aria-hidden="true" style={{ marginRight: "6px", fontSize: "18px" }}></i>
                    {t("SchedulerView-ADD_BILL")}
                </span>,
                "1000px",
            );
    }

    const onDetailAddCart = (bookingId, guestId,) => {
        modalPayment.current &&
            modalPayment.current.onOpen(
                <PaymentWrapView
                    onClose={() => {
                        modalPayment.current && modalPayment.current.onClose();
                    }}
                    onReloadData={() => onResetDataBooking()}
                    bookingId={bookingId}
                    guestId={guestId}
                    branchId={branchId}
                    isDetail={true}
                    step={PaymentConstant.STEP_2_CART_BILL_DETAIL}

                />,
                <span className={"header-title-modal"}>
                    {AppUtil.renderTitleForm(t("SchedulerView-PAYMENT"))}
                </span>,
                "1000px"
            );
    }

    const onProceedPayment = (bookingId, guestId, billId) => {
        modalPayment.current && modalPayment.current.onOpen(
            <PaymentContainer
                onClose={() =>
                    modalPayment.current && modalPayment.current.onClose()
                }
                onReloadData={() => onResetDataBooking()}
                bookingId={bookingId}
                guestId={guestId}
                branchId={branchId}
                step={PaymentConstant.STEP_3_PAYMENT}
                billId={billId} />
            ,
            <span className={"header-title-modal"} style={{
                display: "flex",
                alignItems: "center",
            }}>
                <i class="fa fa-money" aria-hidden="true" style={{
                    fontSize: "18px",
                    marginRight: "6px",
                }}></i>
                {t("SchedulerView-TAKE_PAYMENT")}
            </span>,
            "1000px");
    };

    const onDetailPayment = (bookingId, billId) => {
        modalPayment.current && modalPayment.current.onOpen(
            <PaymentDetailContainer
                onClose={() =>
                    modalPayment.current && modalPayment.current.onClose()
                }
                onReloadData={() => onResetDataBooking()}
                bookingId={bookingId}
                billId={billId}
                viewCanCancelPayment={true}
                branchId={branchId} />
            , <span className={"header-title-modal"}>
                <span className={"header-title-modal"}>
                    {AppUtil.renderTitleForm(t("SchedulerView-DETAIL_PAYMENT"))}
                </span>
            </span>,
            "1000px");
    };


    const onCloseDefault = () => {
    };
    const openOverlay = () => {
        refOverlay.current && refOverlay.current.open(<span
            style={{ color: "#006096", fontSize: 12 }}>Please waiting...</span>);
    };

    const closeOverlay = () => {
        refOverlay.current && refOverlay.current.close();
    };

    //render
    const { date, scrollToCurrentHour } = state;

    const renderModal = () => {
        return <Modal ref={modalRef} onClose={onCloseDefault} maskClosable={false} draggable={true}
            btnMinimize={true}
            widthMinimize={300}
            className="popup-crud popup-container" />;
    };
    const renderModalBookingUpdate = () => {
        return <Modal ref={modalRefUpdate} onClose={onCloseDefault} maskClosable={false} draggable={true}
            btnMinimize={true}
            widthMinimize={300}
            className="popup-crud popup-container" />;
    };
    const renderModalClone = () => {
        return <Modal ref={modalCloneRef} onClose={onCloseDefault} maskClosable={false} draggable={true}
            widthMinimize={300}
            btnMinimize={true}
            className="popup-crud popup-container popup-in-dashboard" />;
    };

    const renderModalPayment = () => {
        return <Modal ref={modalPayment} onClose={onCloseDefault} maskClosable={false} draggable={true}
            widthMinimize={300}
            btnMinimize={true}
            className="popup-crud popup-container popup-method" />;
    };

    const renderModalHistory = () => {
        return <Modal ref={modalRefHistory} onClose={onCloseDefault} maskClosable={false} draggable={true}
            widthMinimize={300}
            btnMinimize={true}
            className="popup-crud popup-container popup-history" />;
    };

    const renderHeader = () => {
        const style = {
            display: "flex",
            marginBottom: 15
        }
        // if (!state.loading && _.isEmpty(configAppointment.technicianSchedule)) {
        //     style.marginBottom = 15
        // }
        return <div className="header-schedules overflow-visible filter-date" style={style}>
            <FilterHeaderLeft branchId={branchId} authData={{}} date={state.date}
                onClickUpdateBooking={onUpdateBookingFromSearch} onChange={onChangeFilter} />
            <FilterHeaderRight />
        </div>
    };

    const renderBody = () => {
        return (
            <div className={`main-dashboard`} style={{ background: '#fff' }}>
                <SchedulerComponent
                    ref={schedulerRef}
                    onProceedPayment={onProceedPayment}
                    onDetailPayment={onDetailPayment}
                    onCreateBooking={onCreateBooking}
                    onUpdateBooking={onUpdateBooking}
                    onUpdateBookingNew={onUpdateBookingNew}
                    onReloadData={onReloadData}
                    scrollToCurrentHour={scrollToCurrentHour}
                    onAddCart={onAddCart}
                    onDetailAddCart={onDetailAddCart}
                    onOpenOverlay={openOverlay}
                    onCloseOverlay={closeOverlay}
                    onResetDataBooking={onResetDataBooking}
                    onResetDataClipboard={onResetDataClipboard}
                    onResetDataTechnician={onResetDataTechnician}
                    preventInterval={preventInterval}
                    continueInterval={continueInterval}
                />
                {renderOverlay()}
            </div>
        );
    };
    const renderOverlay = () => {
        return (
            <Overlay
                ref={refOverlay}
                style={{
                    borderRadius: 3,
                    zIndex: 99
                }}
            />
        );
    };

    const renderBodyOff = () => {
        const note = _.get(configAppointment, "holiday.note", "");
        const height = window.innerHeight - 140;
        return _.get(configAppointment, "isHoliday")
            ? <HolidayOffView
                title={t("SchedulerView-HAPPY_HOLIDAYS!")}
                subTitle={note}
                text={t("Scheduler-SET_UP")} status={500}
                href={"/technician/time-sheet"} />
            : <NotPermission title={t("SchedulerView-NO_TECHNICIAN_WORK")}
                subTitle={t("SchedulerView-PLEASE_SETUP_TECHNICIAN_SCHEDULE")}
                text={t("Scheduler-SET_UP")} status={500}
                height={height}
                href={"/technician/time-sheet"}
                style={{ margin: "10px 0px" }} />
    }
    if (!branchId) {
        return <NotPermission
            isNotShowButton={true}
            title={t("SchedulerView-THE_USER_ACCOUNT_IS_NOT_AUTHORIZED_TO_MANAGE_THIS_BRANCH_._PLEASE_CONTACT_THE_ADMIN_FOR_AUTHORIZATION") + "."}
            subTitle={" "} />
    }

    const {
        bookings,
        configAppointment,
        guests,
        firstLoad,
        dateSelected,
        loading,
        technicianSchedule,
        bookingSchedule,
        waitingList,
        flatAllData
    } = state
    return (
        <BasePermission rightAccess={allRights.APPOINTMENT_ACCESS}>
            <AppointmentProvider date={date}
                branchId={branchId}
                bookings={bookings}
                guests={guests}
                configAppointment={configAppointment}
                firstLoad={firstLoad}
                dateSelected={dateSelected}
                loadingData={loading}
                technicianSchedule={technicianSchedule}
                bookingSchedule={bookingSchedule}
                waitingList={waitingList}
                flatAllData={flatAllData}
            >
                <Container
                    ref={containerRef}
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 56,
                        background: "#EAEFF3"
                    }}>
                    <div className={`appointment-style dashboard-v2`}
                        style={{ width: "100%", padding: "16px 21px 16px 21px", position: 'relative' }}>
                        {renderHeader()}
                        {
                            !loading && (_.get(configAppointment, "isHoliday") || _.isEmpty(state.technicianSchedule)) ? renderBodyOff() : renderBody()
                        }
                    </div>
                    {renderModal()}
                    {renderModalBookingUpdate()}
                    {renderModalClone()}
                    {renderModalPayment()}
                    {renderModalHistory()}
                </Container>
            </AppointmentProvider>
        </BasePermission>
    );
};

export default SchedulerView;
