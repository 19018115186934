/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Modal } from "@idtek/component/lib";
import { addClass, closest, extend, isNullOrUndefined, remove, removeClass } from "@syncfusion/ej2-base";
import { ContextMenuComponent } from "@syncfusion/ej2-react-navigations/context-menu";
import { Day, DragAndDrop, Inject, Resize, Schedule } from "@syncfusion/ej2-react-schedule/index"
import { t } from "@idtek/component/lib/language";
import {
    ResourceDirective,
    ResourcesDirective,
    ScheduleComponent,
    ViewDirective,
    ViewsDirective
} from "@syncfusion/ej2-react-schedule/schedule";
import _ from "lodash";
import moment from "moment";
import React, { forwardRef, lazy, Suspense, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import Overlay from "../../../common/component/Overlay";
import AppUtil from "../../../utils/AppUtil";
import ApiBookingConstant from "../constant/ApiBookingConstant";
import { BookingStatusContant } from "../constant/BookingStatusContant";
import { useAppointmentContext } from "../context/AppointmentContext";
import ClipboardAppointment from "./ClipboardAppointment";
import { ContextMenu } from "../constant/ContextMenu";
import AppointmentRenderUtil from "../util/AppointmentRenderUtil";
import AppointmentContextMenu from "../util/AppointmentContextMenu";
import AppointmentAction from "../util/AppointmentAction";
import AppStore from "../../../store";
import { allRights } from "../../../utils/rightUtils";
import Icon from "@idtek/component/lib/icon/Icon";
import '../style/appoiment-zoom-style.scss';
import '../style/alert-swal-input.scss';
import {
    TECHNICIAN_WORKING_TYPE_OFF,
    TECHNICIAN_WORKING_TYPE_OFF_AL
} from "../../technician/constant/TechnicianConstant";
import MouseTooltipCpn from "./MouseTooltipCpn"
import CreateCustomerModel from "../../customer/model/CreateCustomerModel";
import PrintBillComponent from "./PrintBillComponent";
import Loading from "../../../pages/Loading";
import useWindowSize from "../hook/useWindowSize";
import ResourceHeaderTemplate from "./ResourceHeaderTemplate";
import EventTemplate from "./EventTemplate";
import CellTemplate from "./CellTemplate";
import MajorSlotTemplate from "./MajorSlotTemplate";

const ChangePriceAndSegmentForm = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import("../form/ChangePriceAndSegmentForm")), 100);
    });
});
const TechnicianEditTipGrid = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import("./TechnicianEditTipGrid")), 100);
    });
});
const BookingReviewDetailCustomer = lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import("../../bookingReview/form/BookingReviewDetailCustomer")), 100);
    });

});

Schedule.prototype.animateLayout = (date, string) => {
    return null;
};
const SchedulerComponent = forwardRef(({
    onReloadData,
    onCreateBooking,
    onProceedPayment,
    onDetailPayment,
    onUpdateBooking,
    onUpdateBookingNew,
    scrollToCurrentHour,
    onAddCart,
    onDetailAddCart,
    onOpenOverlay,
    onCloseOverlay,
    onResetDataBooking,
    onResetDataClipboard,
    onResetDataTechnician,
    continueInterval,
    preventInterval
}, ref) => {
    const {
        configAppointment,
        date,
        branchId,
        setDataInitBooking,
        zoomCellPxValue,
        enableDock,
        firstLoad,
        dateSelected,
        loadingData,
        technicianSchedule,
        bookingSchedule,
        waitingList,
        flatAllData
    } = useAppointmentContext();
    const {
        startHour,
        endHour,
        extraEndHour,
        extraStartHour,
        multiSetting,
        confirmBookingOnline
    } = configAppointment;
    const [seconds, setSeconds] = useState(0);
    const [widthWindow, heightWindow] = useWindowSize();
    const height = "calc(100vh - 135px)";
    const scheduleObj = useRef(null),
        refOverlay = useRef(null),
        refMouseTooltipCpn = useRef(null),
        refModal = useRef(null),
        refMenuContext = useRef(null),
        refClipboard = useRef(null),
        refCpnPrintBill = useRef(null),
        refUpdatingSegment = useRef(false),
        refToastObj = useRef(endHour),
        refHaveShowReminder = useRef([]),
        refContainerWrap = useRef(null),
        refConfigAppointment = useRef({}),
        refScrollToCurrentHour = useRef({}),
        refDateSelected = useRef(null)

    let dataPostDrag = {};
    let selectedTarget;
    let tempCount = null;
    // debugger
    useImperativeHandle(ref, () => ({
        getDateSchedule() {
            return moment(scheduleObj.current.selectedDate).format("YYYY-MM-DD")
        }
    }));

    useEffect(() => {
        refScrollToCurrentHour.current = scrollToCurrentHour
    }, [scrollToCurrentHour])


    useEffect(() => {
        refConfigAppointment.current = configAppointment
    }, [configAppointment]);

    useEffect(() => {
        if (branchId && !refUpdatingSegment.current && !firstLoad && !loadingData) {
            const compareArr = _.differenceWith(bookingSchedule, getBookingSchedule(), _.isEqual);
            const compareArr2 = _.differenceWith(getBookingSchedule(), bookingSchedule, _.isEqual);
            if (!_.isEmpty(compareArr) || !_.isEmpty(compareArr2)) {
                scheduleObj.current.eventSettings.dataSource = bookingSchedule;
            }
        }
    }, [bookingSchedule]);

    useEffect(() => {
        if (branchId && !refUpdatingSegment.current && !firstLoad && !loadingData) {
            const techIdNew = _.map(technicianSchedule, item => {
                return {
                    technicianWorkings: item.technicianWorkings,
                    id: item.id
                }
            })
            const techIdOld = _.map(scheduleObj.current.resources[0].dataSource, item => {
                return {
                    technicianWorkings: item.technicianWorkings,
                    id: item.id
                }
            })
            const compareArr = _.differenceWith(techIdNew, techIdOld, _.isEqual);
            const compareArr2 = _.differenceWith(techIdOld, techIdNew, _.isEqual);

            if (!_.isEmpty(compareArr) || !_.isEmpty(compareArr2)) {
                openOverlay()
                scheduleObj.current.resources[0].dataSource = technicianSchedule
                scheduleObj.current.dataBind()
            }
        }

    }, [technicianSchedule]);

    useEffect(() => {


        if (branchId && !refUpdatingSegment.current && !firstLoad && !loadingData) {
            scheduleObj.current.startHour = extraStartHour ? extraStartHour : startHour;
            scheduleObj.current.endHour = extraEndHour ? extraEndHour : endHour;
            scheduleObj.current.selectedDate = dateSelected;
        }
    }, [dateSelected]);

    useEffect(() => {
        openOverlay()
        scheduleObj.current.cssClass = "schedule-cell-dimension-" + zoomCellPxValue
        scheduleObj.current.dataBind();
        scheduleObj.current.refresh();
    }, [zoomCellPxValue])

    useEffect(() => {
        const widthClipboard = enableDock ? 180 : 0;
        const widthContainer = widthWindow - widthClipboard - 42;
        if (widthContainer > 0) {
            const emp = getTechnicanSchedule()
            const widthColAvg = widthContainer / _.size(emp)
            let widthCol = 0
            if (widthColAvg > zoomCellPxValue) {
                widthCol = Math.ceil(parseInt(widthColAvg) / 10) * 10;
            } else if (widthColAvg <= 40) {
                widthCol = 40
            } else {
                widthCol = zoomCellPxValue
            }
            const cellWidthCls = "header-cell-" + widthCol
            if (refContainerWrap.current && refContainerWrap.current.classList) {
                const classWrap = refContainerWrap.current.classList;
                const oldClass = _.find(classWrap, x => _.includes(x, 'header-cell-'))
                if (oldClass && oldClass !== cellWidthCls) {
                    _.each(classWrap, x => {
                        if (_.includes(x, oldClass)) {
                            classWrap.remove(x);
                        }
                    })
                }
                setTimeout(() => {
                    classWrap.add(cellWidthCls);
                }, 100)
            }

        }
    }, [widthWindow, enableDock, zoomCellPxValue, dateSelected])


    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setSeconds(seconds => seconds + 1);
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);
    //
    // useEffect(() => {
    //     refreshEventReminder()
    // }, [seconds])
    //
    // const refreshEventReminder = () => {
    //     const template = templateRemind()
    //
    //     if (template) {
    //         notification.open({
    //             description: template,
    //             duration: 0,
    //             className: "appointment-reminder"
    //         })
    //     }
    // }
    const checkRightAction = () => {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        return AppUtil.checkPermission(allRights.BOOKING_CREATE, rights);
    }

    const onCreated = () => {
        // if (moment(dateSelected).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") && isScroll) {
        //     openOverlay();
        //     setTimeout(() => {
        //         onScroll();
        //         closeOverlay();
        //     }, 1500);
        // } else {
        //     closeOverlay();
        // }
    };

    const getTechnicanSchedule = () => {
        return scheduleObj.current && scheduleObj.current.resources[0].dataSource;
    };

    const getActiveCellsData = () => {
        let selectedCells = scheduleObj.current.getSelectedElements();
        return scheduleObj.current.getCellDetails(selectedCells.length > 0 ? selectedCells : selectedTarget);
    };

    const getBookingSchedule = () => {
        return scheduleObj.current && scheduleObj.current.eventSettings.dataSource;
    };



    const onDataBinding = (args) => {
        const tmpTechList = getTechnicanSchedule();
        const currentViewDates = scheduleObj.current.getCurrentViewDates();
        if (_.size(tmpTechList) > 0) {
            const { startHour, endHour } = refConfigAppointment.current
            if (currentViewDates[0] && moment(currentViewDates[0]).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                scheduleObj.current.resetWorkHours();
            } else {
                scheduleObj.current.resetWorkHours();
                _.each(tmpTechList, (item, index) => {
                    if (_.size(item.technicianWorkings) > 0) {
                        scheduleObj.current.resetWorkHours([currentViewDates], startHour, endHour, index);
                        _.each(item.technicianWorkings, (t) => {
                            if (t.type === "WORKING") {
                                scheduleObj.current.setWorkHours([currentViewDates], moment(t.startDate).format("HH:mm"), moment(t.endDate).format("HH:mm"), index);
                            }
                        });
                    } else {
                        scheduleObj.current.setWorkHours([currentViewDates], startHour, endHour, index);
                    }
                });
            }
            onScroll();
            // if (refScrollToCurrentHour.current) {
            //     onScroll();
            // }
            // if (!refScrollToCurrentHour.current) {
            //     setTimeout(() => {
            //         closeOverlay();
            //     }, 700);
            // }
        }

    };

    const onScroll = () => {
        const newDate = moment().subtract(60, "minutes");
        const roundedDate = round(newDate, moment.duration(-15, "minutes"), "ceil");
        setTimeout(() => {
            if (refScrollToCurrentHour.current) scheduleObj.current && scheduleObj.current.scrollTo(roundedDate.format("HH:mm"));
            closeOverlay();
        }, 700)
    };

    const round = (date, duration, method) => {
        return moment(Math[method](+date / +duration) * +duration);
    };

    const toggleMouseTooltip = (date) => {
        refMouseTooltipCpn.current && refMouseTooltipCpn.current.toggleMouseTooltip(moment(date).format("HH:mm A"));
    };

    const onClickMouse = () => {
        refMouseTooltipCpn.current && refMouseTooltipCpn.current.onClickMouse();
    };

    const onCloseDefault = () => {
    };

    const onPopupOpen = (args) => {
        args.cancel = true;
    };

    const onActionComplete = (args) => {
    };

    const onActionBegin = async (args) => {
        if (!checkRightAction()) {
            args.cancel = true;
            return;
        }
        if (args) {
            if (args.requestType === "dateNavigate") {
                if (args.event && (args.event.action === "rightArrow" || args.event.action === "leftArrow")) {
                    args.cancel = true;
                }
            }
            if (args.requestType === "eventChange" && dataPostDrag && !_.isEmpty(dataPostDrag)) {
                const { dataPost, dataClone, update } = dataPostDrag;
                const groupIndex = _.findIndex(getTechnicanSchedule(), (x) => x.id === dataPost.technicianId);
                const isSlot = scheduleObj.current.isSlotAvailable(dataClone.StartTime, dataClone.EndTime, groupIndex);
                if (!isSlot && !multiSetting) {
                    AppUtil.ToastWarning(t('SchedulerComponent-NOT_ALLOW_MULTITASKING!'))
                    args.cancel = true;
                    scheduleObj.current.refreshEvents();
                    return;
                } else {
                    await updateBookingItem(dataPost, dataClone, update, () => onResetDataBooking());
                    args.cancel = true;
                }
            }
        }
    };

    const onItemDragDB = (args) => {
        const { event, data, startTime, endTime } = args;
        if (event.name === "drag") {
            const format = "YYYY-MM-DD HH:mm:ss";
            let cellData = scheduleObj.current.getCellDetails(event.target);
            let incondition = true;
            if (!cellData) {
                incondition = false
            } else {
                let resourceDetails = scheduleObj.current.getResourcesByIndex(cellData.groupIndex);
                //check skill
                const serviceMapping = _.get(resourceDetails.resourceData, 'technicianServiceMappings');
                const skills = _.map(serviceMapping, (mapp) => mapp.serviceId);
                if (_.indexOf(skills, data.ServiceId) < 0) {
                    incondition = false
                }
                //check time sheet

                const timeSheetOfTech = _.get(resourceDetails.resourceData, 'technicianWorkings');
                const timeOff = _.filter(timeSheetOfTech, x => x.type === TECHNICIAN_WORKING_TYPE_OFF)
                const start = moment(startTime).format(format)
                const end = moment(endTime).format(format)
                _.each(timeOff, item => {
                    const startOff = moment(item.startDate).format(format)
                    const endOff = moment(item.endDate).format(format)
                    if (start > startOff && start < endOff) {
                        incondition = false
                    }
                    if (start < startOff && end > startOff) {
                        incondition = false
                    }
                })
            }


            let dragElement = document.querySelectorAll(".e-appointment.e-schedule-event-clone.e-drag-clone");
            if (incondition) {
                if (dragElement[0].classList.contains("e-cannot-drop-custom-db")) {
                    dragElement[0].classList.remove("e-cannot-drop-custom-db");
                }
                if (multiSetting) {
                    document.body.style.cursor = "";
                    dragElement[0].classList.add("e-can-drop-custom-db");
                }
            } else {
                if (dragElement[0].classList.contains("e-can-drop-custom-db")) {
                    dragElement[0].classList.remove("e-can-drop-custom-db");
                }
                dragElement[0].classList.add("e-cannot-drop-custom-db");
            }

        }
    };

    const onDragStart = (args) => {
        refMouseTooltipCpn.current && refMouseTooltipCpn.current.onChangeVisible(false);
        refMenuContext.current && refMenuContext.current.close();
        refUpdatingSegment.current = true
        if (args) {
            const { data } = args;
            eventClick(data)
            args.interval = 0.5;
            if (_.indexOf([BookingStatusContant.STATUS_WAITING, BookingStatusContant.STATUS_PROCESSING, BookingStatusContant.STATUS_CHECKIN], data.Status) < 0) {
                args.cancel = true;
            } else {
                if (data.Type === "services") {
                    const currentViewDates = scheduleObj.current.getCurrentViewDates();
                    const clone = _.cloneDeep(getTechnicanSchedule());
                    _.each(clone, (x, idx) => {
                        const skills = _.map(x.technicianServiceMappings, (mapp) => mapp.serviceId);
                        if (data.Block) {
                            if (data.TechnicianId !== x.id) {
                                scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                            } else {
                                if (_.indexOf(skills, data.ServiceId) < 0) {
                                    scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                                }
                            }
                        } else {
                            if ((data.Gender && data.Gender !== x.gender) || (_.indexOf(skills, data.ServiceId) < 0)) {
                                scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                            }

                        }

                    });
                }
            }
        }
    };

    const onDragStop = (args) => {
        const { event, data, } = args;
        const dataClone = _.cloneDeep(data);
        // const {startTime, endTime} = data

        if (event.name === "dragStop") {
            const format = "YYYY-MM-DD HH:mm:ss";
            let cellData = scheduleObj.current.getCellDetails(event.target);
            let incondition = true;
            if (!cellData) {
                incondition = false
            }
            //check backend cho nhẹ
            // else {
            //     let resourceDetails = scheduleObj.current.getResourcesByIndex(cellData.groupIndex);
            //     const timeSheetOfTech = _.get(resourceDetails.resourceData, 'technicianWorkings');
            //     const timeOff = _.filter(timeSheetOfTech, x => x.type === TECHNICIAN_WORKING_TYPE_OFF)
            //     const start = moment(startTime).format(format)
            //     const end = moment(endTime).format(format)
            //     console.log('Function: onDragStop -> start, end: ', start, end);
            //     _.each(timeOff, item => {
            //         const startOff = moment(item.startDate).format(format)
            //         const endOff = moment(item.endDate).format(format)
            //         if (start > startOff && start < endOff) {
            //             incondition = false
            //         }
            //         if (start < startOff && end > startOff) {
            //             incondition = false
            //         }
            //     })
            // }

            if (incondition && event.target.classList.contains("e-work-hours")) {
                const dataEvent = scheduleObj.current.getEvents();
                const rawData = _.find(dataEvent, (x) => x.Id === dataClone.Id);
                let cellData = scheduleObj.current.getCellDetails(event.target);
                let resourceDetails = scheduleObj.current.getResourcesByIndex(cellData.groupIndex);
                const technician = resourceDetails.resourceData.id;
                if (moment(rawData.StartTime).format("YYYY-MM-DD HH:mm") !== moment(dataClone.StartTime).format("YYYY-MM-DD HH:mm") || rawData.TechnicianId !== technician) {
                    const startTime = moment(dataClone.StartTime).format("YYYY-MM-DD HH:mm:ss");
                    // const endTime = moment(dataClone.StartTime).add(dataClone.ServiceTime, "minutes").format("YYYY-MM-DD HH:mm:ss");
                    const dataPost = {
                        id: dataClone.BookingItemId,
                        startTime,
                        // endTime,
                        technicianId: technician,
                        bookingId: dataClone.BookingId,
                        force: false
                    };
                    dataPostDrag = {
                        dataPost,
                        dataClone,
                        update: true
                    };
                } else {
                    // this.scrollToCurrentHour = false;
                    args.cancel = true;
                    scheduleObj.current.refreshEvents();
                }
            } else {
                // this.scrollToCurrentHour = false;
                args.cancel = true;
                scheduleObj.current.refreshEvents();
            }
            refUpdatingSegment.current = false

        }
    };

    const removeBlur = () => {
        let selectedService = document.querySelectorAll(".e-appointment-border-blur");
        _.each(selectedService, (x) => {
            x.classList.remove("e-appointment-border-blur");
        });
    }

    const removeShadow = () => {
        let selectedService = document.querySelectorAll(".e-appointment-border");
        _.each(selectedService, (x) => {
            x.classList.remove("e-appointment-border");
        });
    }
    const onResizeStart = (args) => {
        const { data } = args
        if (moment(scheduleObj.current.selectedDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
            || _.indexOf([BookingStatusContant.STATUS_WAITING, BookingStatusContant.STATUS_PROCESSING, BookingStatusContant.STATUS_CHECKIN], data.Status) < 0) {
            args.cancel = true;
        } else {
            //scheduleObj.current.eventSettings.enableTooltip = false
            args.interval = 1;
            refUpdatingSegment.current = true
        }

    }

    const onResizeStop = (args) => {
        const { data } = args
        const dataBooking = getBookingSchedule()
        const findSegment = _.find(dataBooking, x => x.Id === data.Id)
        if (findSegment) {
            const obj = {
                time: moment(findSegment.StartTime).format("HH:mm") + " ~ " + moment(findSegment.EndTime).format("HH:mm"),
                name: findSegment.ServiceName,
                duration: findSegment.ServiceTime,
                price: findSegment.ServicePrice
            }
            const newDuration = moment(data.EndTime).diff(moment(data.StartTime), 'minutes')
            if (newDuration != findSegment.ServiceTime) {
                const timeNew = moment(data.StartTime).format("HH:mm") + " ~ " + moment(data.EndTime).format("HH:mm")
                refModal.current && refModal.current.onOpen(
                    <Suspense fallback={<Loading style={{ height: 300 }} />}>
                        <ChangePriceAndSegmentForm
                            onClose={(reset) => {
                                refModal.current.onClose()
                                //scheduleObj.current.eventSettings.enableTooltip = true
                                refUpdatingSegment.current = false
                                onResetDataBooking && onResetDataBooking()
                            }}
                            initialValues={{
                                price: findSegment.ServicePrice,
                                duration: newDuration,
                                timeNew,
                                startTime: moment(data.StartTime),
                            }}
                            params={{
                                id: findSegment.BookingItemId,
                                force: false,
                                startTime: moment(data.StartTime).format("YYYY-MM-DD HH:mm"),
                                technicianId: data.TechnicianId,
                                bookingId: findSegment.BookingId
                            }}
                            data={[obj]}
                        />
                    </Suspense>,
                    t("SchedulerComponent-CHANGE_DURATION_AND_PRICE"), '600px'
                );
            }

        }

    }

    const onCellClick = (args) => {
        removeBlur()
    };

    const onCellDoubleClick = (args) => {
    };

    const eventClick = (event) => {
        // refOverlayEvent.current && refOverlayEvent.current.open(<></>);
        removeBlur()
        removeShadow()
        const bookingItems = _.filter(getBookingSchedule(), (x) => x.BookingId === event.BookingId);
        const arrIds = _.map(bookingItems, (x) => "Appointment_" + x.Id);
        let appointments = document.querySelectorAll(".e-appointment.e-lib");
        const itemsNode = _.filter(appointments, (x) => _.indexOf(arrIds, x.getAttribute("data-id")) >= 0);
        const itemsNodeOpacity = _.filter(appointments, (x) => _.indexOf(arrIds, x.getAttribute("data-id")) < 0);
        _.each(itemsNode, (node) => {
            node.classList.add("e-appointment-border");
        });
        _.each(itemsNodeOpacity, (node) => {
            node.classList.add("e-appointment-border-blur");
        });
    };

    const formDefaults = {
        alignLabel: "left",
        widthLabel: "130px"
    };

    const openDetailCustomer = async (e, customerId) => {
        const getCustomer = await AppUtil.getApi(`/customer/show-detail/${customerId}`)
        let dataCustomer = []
        if (_.get(getCustomer, "data.success")) {
            dataCustomer = _.get(getCustomer, "data.result")
        }
        const FormModal = BookingReviewDetailCustomer;
        let newModel = _.cloneDeep(CreateCustomerModel);
        _.mapKeys(newModel.fields, (value, key) => {
            if (key) {
                value.visibleIcon = false;
                value.placeholder = " ";
            }
        });
        refModal.current.onOpen(
            <Suspense fallback={<Loading style={{ height: 300 }} />}>
                <FormModal
                    onClose={(flag, dataRow) => {
                        refModal.current.onClose()
                    }}
                    formDefaults={formDefaults}
                    model={newModel}
                    initialValues={dataCustomer}
                />
            </Suspense>,
            t('SchedulerComponent-DETAIL_CUSTOMER'), '900px'
        );
    };


    const onContextMenuBeforeClose = () => {
        scheduleObj.current.removeSelectedClass();
    }

    const onContextMenuBeforeOpen = (args) => {
        const { readOnly } = refConfigAppointment.current
        if (readOnly) {
            args.cancel = true;
            return;
        }
        const { target } = args.event;
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        let newEventElement = document.querySelector(".e-new-event");
        if (newEventElement) {
            remove(newEventElement);
            removeClass([document.querySelector(".e-selected-cell")], "e-selected-cell");
        }
        let targetElement = args.event.target;
        if (closest(targetElement, ".e-contextmenu")) {
            return;
        }
        refMenuContext.current.showItems(["offTimeTech", "offTimeTechAL"], true);
        const codeHides = [
            "cancel", "cancel-group", "no-show", "no-show-group",
            "check-in", "start", "start-group",
            "check-in-group", "payment-group", "add", "save", "complete",
            "payment", "update", "detail", "detailPayment", "cancelBill", "print",
            "clipboard", "confirm", "resend-sms", "print-slip", "tipTechnician", "hasTipTechnician",
            "cart-group", "cart", "cart-detail", "back-to-waiting", "back-to-checkin"]
        const rightUpdateBreakTime = AppUtil.checkPermission(allRights.APPOINTMENT_TIMESHEET_UPDATE, rights)
        if (!rightUpdateBreakTime) codeHides.push('offTimeTech', 'offTimeTechAL')
        refMenuContext.current.hideItems(codeHides, true);
        selectedTarget = closest(targetElement, ".e-appointment,.e-work-hours," + ".e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells");
        if (moment(scheduleObj.current.selectedDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
            args.cancel = true;
        }
        if (isNullOrUndefined(selectedTarget)) {
            args.cancel = true;
        } else {
            //check right action
            const rightAction = checkRightAction()
            if (!rightAction) {
                refMenuContext.current.showItems(["detail"], true);
                return
            }
            //data celltrong
            const cellData = scheduleObj.current.getCellDetails(target);
            // data cell event
            const eventObj = scheduleObj.current.getEventDetails(selectedTarget);
            if (!_.isEmpty(eventObj)) {
                eventClick(eventObj)
            }
            AppointmentContextMenu.checkBeforeOpenContextMenu(args, cellData, eventObj, getBookingSchedule, selectedTarget, refMenuContext, scheduleObj, waitingList, multiSetting)
        }
    };

    const onMenuItemSelect = (args) => {
        let selectedMenuItem = args.item.id;
        let node;
        if (selectedTarget.classList.contains("e-appointment")) {
            node = scheduleObj.current.getEventDetails(selectedTarget);
        }
        switch (selectedMenuItem) {
            case "start-only":
            case "start-all":
            case "start":
            case "check-in-only":
            case "check-in-all":
            case "check-in":
            case "cancel-only":
            case "cancel-all":
            case "cancel":
            case "no-show-only":
            case "no-show-all":
            case "no-show":
            case "complete":
                if (node) {
                    if (selectedMenuItem.startsWith("start") && moment().format("HH:mm") < scheduleObj.current.startHour) {
                        AppUtil.ToastWarning(t('SchedulerComponent-THIS_ACTION_CANNOT_BE_COMPLETED_AT_THIS_TIME'));
                        return;
                    }
                    doActionUpdateStatusBooking(selectedMenuItem, node)
                }
                break;
            case "resend-sms":
                if (node) {
                    showConfirm(t('SchedulerComponent-CONFIRM'), `${t("SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_" + selectedMenuItem.toUpperCase())} ${t("SchedulerComponent-BOOKING")} ${node.BookingCode}?`, () => doActionRemindBooking(selectedMenuItem, node));
                }
                break;
            case "confirm":
                if (node) {
                    showConfirm(t('SchedulerComponent-CONFIRM'), `${t("SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_" + selectedMenuItem.toUpperCase())} ${t("SchedulerComponent-BOOKING")} ${node.BookingCode}?`, () => doActionConfirmBooking(selectedMenuItem, node));
                }
                break;
            case "add":
                const activeCellsData = getActiveCellsData();
                const tmpTechList = getTechnicanSchedule();
                const technician = tmpTechList[activeCellsData.groupIndex];
                let start = activeCellsData.startTime;
                if (!multiSetting) {
                    const format = "YYYY-MM-DD HH:mm:ss";
                    const findAbove = _.find(
                        getBookingSchedule(),
                        (x) => x.TechnicianId === technician.id && moment(x.EndTime).format(format) > moment(activeCellsData.startTime).format(format) && moment(x.EndTime).format(format) < moment(activeCellsData.endTime).format(format)
                    );
                    if (findAbove) {
                        start = findAbove.EndTime;
                    }
                }
                const init = {
                    technicianId: technician.id,
                    startTime: moment(start)
                };
                setDataInitBooking(init);
                onCreateBooking && onCreateBooking();
                break;
            case "update":
                if (node) {
                    const init = { technicianId: node.TechnicianId };
                    setDataInitBooking(init);
                    onUpdateBooking && onUpdateBooking(node.BookingId, node.GuestId, node.BookingCode, node.BookingStatus);
                }
                break;
            case "detail":
                if (node) {
                    const init = { technicianId: node.TechnicianId };
                    setDataInitBooking(init);
                    onUpdateBooking && onUpdateBooking(node.BookingId, node.GuestId, node.BookingCode, node.BookingStatus, true);
                }
                break;
            case "cart-detail":
                if (node) {
                    onDetailAddCart && onDetailAddCart(node.BookingId, node.GuestId)
                }
                break;
            case "cart":
            case "cart-only":
                if (node) {
                    onAddCart && onAddCart(node.BookingId, node.GuestId)
                }
                break;
            case "cart-all":
                if (node) {
                    const appoints = getBookingSchedule()
                    const guestIds = _.map(
                        _.uniqBy(
                            _.filter(appoints, x => x.BookingId === node.BookingId && x.Status === BookingStatusContant.STATUS_PROCESSING && _.size(x.BillBookingMappings) === 0), g => g.GuestId),
                        u => u.GuestId)
                    onAddCart && onAddCart(node.BookingId, guestIds)
                }
                break;
            case "payment":
            case "payment-only":
                if (node) {
                    onProceedPayment && onProceedPayment(node.BookingId, node.GuestId, node.BillId);
                }
                break;
            case "payment-all":
                if (node) {
                    const appoints = getBookingSchedule()
                    const guestIds = _.map(
                        _.uniqBy(
                            _.filter(appoints, x => x.BookingId === node.BookingId && x.Status === BookingStatusContant.STATUS_PROCESSING && _.size(x.BillBookingMappings) === 0), g => g.GuestId),
                        u => u.GuestId)
                    onProceedPayment && onProceedPayment(node.BookingId, guestIds, node.BillId);
                }
                break;
            case "detailPayment":
                if (node) {
                    onDetailPayment && onDetailPayment(node.BookingId, node.BillId);
                }
                break;
            case "cancelBill":
                if (node) {
                    onCancelBill && onCancelBill(node.BookingId, node.BillId);
                }
                break;
            case "edit-payment":
                if (node) {
                }
                break;
            case "print":
                if (node) {
                    printBill(node.GuestId);
                }
                break;

            case "clipboard":
                if (node) {
                    showConfirm(t('SchedulerComponent-CONFIRM'), `${t("SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_MOVE_BOOKING")} ${node.BookingCode} ${t("SchedulerComponent-TO_CLIPBOARD")}?`, () => onMoveToClipBoard(node.GuestId));
                }
                break;
            case "print-slip":
                if (node) {
                    printBillSlipAction(node && node.BookingId, node.GuestId);
                }
                break;
            case "offTimeTech":
            case "offTimeTechAL":
                const activeCellsData1 = getActiveCellsData();
                const activeCell = []
                if (activeCellsData1) {
                    _.each(activeCellsData1.element, x => {
                        if (x.classList && x.classList.contains("e-work-hours")) {
                            activeCell.push(x)
                        }
                    })
                }
                if (!_.isEmpty(activeCell)) {
                    const startCell = scheduleObj.current.getCellDetails(activeCell[0])
                    const endCell = scheduleObj.current.getCellDetails(_.last(activeCell))
                    const tmpTechList1 = getTechnicanSchedule();
                    const technician1 = tmpTechList1[activeCellsData1.groupIndex];
                    const type = selectedMenuItem === "offTimeTech" ? TECHNICIAN_WORKING_TYPE_OFF : TECHNICIAN_WORKING_TYPE_OFF_AL
                    let message = `${t("SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_SET_UP_BREAK_TIME_FOR")} ${technician1.name} 
                        ${t("SchedulerComponent-FROM")} ${moment(startCell.startTime).format("hh:mm A")} 
                        ${t("SchedulerComponent-TO")} ${moment(endCell.endTime).format("hh:mm A")} ?`
                    showConfirmBreakTime(t('SchedulerComponent-CONFIRM'), message, startCell.startTime, endCell.endTime, technician1, type);
                }
                break;
            case "tipTechnician":
            case "hasTipTechnician":
                if (node) {
                    refModal.current && refModal.current.onOpen(
                        <Suspense fallback={<Loading style={{ height: 300 }} />}>
                            <TechnicianEditTipGrid
                                onClose={(flag,) => {
                                    if (flag) {
                                        onResetDataBooking();
                                    }
                                    refModal.current && refModal.current.onClose();
                                }}
                                billId={node.BillId}
                                alreadyTip={node.AlreadyTip}
                                guestId={node.GuestId}
                            />
                        </Suspense>,
                        renderTitleForm(t('SchedulerComponent-DISTRIBUTE_TIP')), '850px'
                    )
                }
                break;
            case "back-to-checkin":
            case "back-to-waiting":
                if (node) {
                    showConfirm(t('SchedulerComponent-CONFIRM'), `${t("SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_BACK_STATUS_GUEST")}?`,
                        () => onBackStatusGuest(node.GuestId));
                }
            default:
            // code block
        }
    };

    const onCancelBill = async (bookingId, billId) => {
        const confirm = await Alert.Swal_confirm(t('SchedulerComponent-CONFIRM'), t('SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THIS_BILL?'), 3);

        if (_.get(confirm, "value") === true) {
            const res = await AppUtil.getApi(`/bill/cancel-bill`, { id: billId })
            if (_.get(res, "data.success")) {
                AppUtil.ToastSaveSuccess(t("SchedulerComponent-CANCEL_SUCCESS"));
                onResetDataBooking();
            }
        }
    };

    const openOverlay = () => {
        onOpenOverlay && onOpenOverlay()
    };

    const closeOverlay = () => {
        onCloseOverlay && onCloseOverlay()
    };
    //action context

    const onBackStatusGuest = async (GuestId) => {
        const response = await AppUtil.postApi("/booking/back-status", { guestId: GuestId });
        if (_.get(response, "data.success")) {
            AppUtil.ToastSaveSuccess();
            onResetDataBooking();
        } else {
            closeOverlay();
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };


    const onMoveToClipBoard = async (GuestId) => {
        const response = await AppUtil.getApi("/booking/move-guest-to-clipboard", { guestId: GuestId });
        if (_.get(response, "data.success")) {
            onResetDataBooking();
            onResetDataClipboard();
            AppUtil.ToastSaveSuccess("SchedulerComponent-MOVE_SUCCESS");
        } else {
            closeOverlay();
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };


    const printBill = async (GuestId) => {
        const response = await AppUtil.getApi(ApiBookingConstant.API_PRINT_BILL, { guestId: GuestId });
        if (_.get(response, "data.success")) {
            const template = response.data.result;
            refCpnPrintBill.current && refCpnPrintBill.current.onChangBill(template);
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };

    const printBillSlipAction = async (BookingId, GuestId) => {
        const response = await AppUtil.getApi(ApiBookingConstant.API_PRINT_BILL_SLIP, {
            guestId: GuestId,
            bookingId: BookingId
        });
        if (_.get(response, "data.success")) {
            const template = response.data.result;
            refCpnPrintBill.current && refCpnPrintBill.current.onChangBill(template);
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };

    // const sendEmailReview = (id) => {
    //     // const params = { id: id };
    //     // const response = AppUtil.getApi("/booking/send-mail-review", params);
    // };

    const showConfirm = async (titleConfirm, messageConfirm, callback) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3,);
        if (_.get(confirm, "value") === true) {
            callback && callback();
        }
    };


    const showConfirmBreakTime = async (titleConfirm, messageConfirm, start, end, technician, type) => {
        let confirm = await Alert.Swal_confirm(titleConfirm, messageConfirm, 3, { input: 'text' });
        const value = _.get(confirm, "value", null);
        if (_.get(confirm, "isConfirmed") === true) {
            const params = {
                technicianId: technician.id,
                startTimeOff: moment(start).format("YYYY-MM-DD HH:mm"),
                endTimeOff: moment(end).format("YYYY-MM-DD HH:mm"),
                branchId,
                date: moment(scheduleObj.current.selectedDate).format("YYYY-MM-DD"),
                note: value,
                type
            }
            const response = await AppUtil.postApi("/timesheet/break-time-dashboard", params);
            if (_.get(response, "data.success")) {
                onResetDataTechnician();
            } else {
                AppUtil.ToastApiError(_.get(response, "data.message"));
            }
        }
    }

    const doActionConfirmBooking = async (actionName, node) => {
        const params = { id: node.BookingId, confirm: true };
        const response = await AppUtil.postApi("/booking/confirm-booking", params);
        if (_.get(response, "data.success")) {
            AppUtil.ToastSuccess();
            onResetDataBooking(scheduleObj.current.date);
        } else {
            AppUtil.ToastApiError();
        }
    };

    const doActionRemindBooking = async (actionName, node) => {
        const params = { id: node.BookingId, confirm: true, remind: true };
        const response = await AppUtil.postApi("/booking/remind-booking", params);
        if (_.get(response, "data.success")) {
            AppUtil.ToastSuccess();
            onResetDataBooking();
        } else {
            AppUtil.ToastApiError(_.get(response, "data.message"));
        }
    };

    const doActionUpdateStatusBooking = async (actionName, node) => {
        await AppointmentAction.doActionUpdateStatusBooking(actionName, node, onResetDataBooking, getBookingSchedule)
    };

    const updateBookingItem = async (dataPost, node, update = false, callback) => {
        const res = await AppUtil.postApi(ApiBookingConstant.API_UPDATE_SEGMENT, dataPost);
        dataPostDrag = {};
        if (_.get(res, "data.success")) {
            const result = _.get(res, "data.result")
            callback && callback()
            AppUtil.ToastUpdateSuccess();
        } else {
            AppUtil.ToastApiError(_.get(res, "data.message"));
            scheduleObj.current.refreshEvents();
        }
    };
    //clipboard

    const updateBooking = async (dataPost) => {
        dataPost.scheduleDate = dateSelected;
        const res = await AppUtil.postApi("/booking/move-guest-to-dashboard", dataPost);
        if (_.get(res, "data.success")) {
            onReloadData && onReloadData();
            AppUtil.ToastSaveSuccess();
        } else {
            AppUtil.ToastApiError(_.get(res, "data.message"));
            scheduleObj.current.refreshEvents();
        }
    };

    const onTreeDragStop = async (event) => {
        let treeElement = closest(event.target, ".e-treeview");
        let classElement = scheduleObj.current.element.querySelector(".e-device-hover");
        if (classElement) {
            classElement.classList.remove("e-device-hover");
        }
        if (["e-drop-in", "e-drop-out", "e-drop-next"].indexOf(event.dropIndicator) >= 0) {
            event.cancel = true;
            scheduleObj.current.refreshEvents();
            return;
        }
        if (treeElement) {
            event.cancel = true;
            scheduleObj.current.refreshEvents();
            return;
        } else {
            let scheduleElement = closest(event.target, ".e-content-wrap");
            if (scheduleElement) {
                if (event.target.classList.contains("e-work-hours")) {
                    const nodes = refClipboard.current.getTreeData(event.draggedNodeData.id);
                    const node = nodes[0];
                    let cellData = scheduleObj.current.getCellDetails(event.target);
                    let resourceDetails = scheduleObj.current.getResourcesByIndex(cellData.groupIndex);
                    const technician = resourceDetails.resourceData.id;
                    const startTime = moment(cellData.startTime).format("YYYY-MM-DD HH:mm:ss");
                    // const endTime = moment(cellData.startTime).add(node.ServiceTime, "minutes").format("YYYY-MM-DD HH:mm:ss");
                    if (node.guestId) {
                        const dataPost = {
                            id: node.BookingItemId,
                            startTime,
                            // endTime,
                            technicianId: technician,
                            bookingId: node.BookingId,
                            force: false
                        };
                        event.cancel = true;
                        updateBookingItem(dataPost, node, false, () => onResetDataClipboard());
                    } else {
                        const dataPost = {
                            guestId: node.id,
                            startTime: startTime,
                            technicianId: technician,
                            branchId: branchId,
                            force: false
                        };
                        updateBooking(dataPost);
                    }
                } else {
                    event.cancel = true;
                    scheduleObj.current.refreshEvents();
                    return;
                }
            } else {
                event.cancel = true;
                scheduleObj.current.refreshEvents();
                return;
            }
        }
    };

    const onTreeDragStart = async (event) => {
        refMouseTooltipCpn.current && refMouseTooltipCpn.current.onChangeVisible(false);
        const { draggedNodeData } = event;
        const treeInstance = refClipboard.current.getTreeInstance();
        treeInstance.selectedNodes = [draggedNodeData.id];
        const nodes = refClipboard.current.getTreeData(draggedNodeData.id);
        const node = nodes[0];
        const techs = getTechnicanSchedule();
        const currentViewDates = scheduleObj.current.getCurrentViewDates();
        if (node.status === BookingStatusContant.STATUS_NEW && node.bookingId) {
            event.cancel = true;
            scheduleObj.current.refreshEvents();
            return;
        }
        if (node.guestId) {
            event.cancel = true;
            return;
        } else {
            const serviceIds = _.map(node.segments, (x) => x.serviceId);
            _.each(techs, (x, idx) => {
                const serviceTechCanDo = _.map(x.technicianServiceMappings, (mapp) => mapp.serviceId);
                //phép giao
                const intersection = _.intersection(serviceIds, serviceTechCanDo);
                if (_.isEmpty(intersection)) {
                    scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                }
                if (node.gender) {
                    if (node.gender === "FEMALE" && x.gender !== "FEMALE") {
                        scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                    } else if (node.gender === "MALE" && x.gender !== "MALE") {
                        scheduleObj.current.resetWorkHours([currentViewDates], "00:00", "24:00", idx);
                    }
                }
            });
        }
    };

    const onItemDrag = (event) => {
        if (scheduleObj.current.isAdaptive) {
            let classElement = scheduleObj.current.element.querySelector(".e-device-hover");
            if (classElement) {
                classElement.classList.remove("e-device-hover");
            }
            if (event.target.classList.contains("e-work-cells")) {
                addClass([event.target], "e-device-hover");
            }
        }
        if (event.target.classList.contains("e-work-cells")) {
            let contentArea = document.querySelector(".e-content-wrap").getBoundingClientRect();
            let targetArea = event.target.getBoundingClientRect();
            let scroll = document.querySelector(".e-content-wrap").scrollLeft;
            let scrollableWidth = document.querySelector(".e-content-table").offsetWidth;
            let scrollRight = scroll + contentArea.width;
            if (contentArea.left >= targetArea.left - 100 && scroll > 0) {
                document.querySelector(".e-content-wrap").scrollLeft = scroll - 50;
            }
            if (contentArea.right <= targetArea.right + 100 && scrollRight < scrollableWidth) {
                document.querySelector(".e-content-wrap").scrollLeft = scroll + 50;
            }
        }

        if (event.name === "nodeDragging") {
            let dragElementIcon = document.querySelectorAll(".e-drag-item.treeview-external-drag .e-icons");
            if (event.target.classList.contains("e-work-hours")) {
                dragElementIcon[0].classList.add("e-can-drop-custom");
                if (document.body.style.cursor === "not-allowed") {
                    document.body.style.cursor = "";
                }
            } else {
                if (dragElementIcon[0].classList.contains("e-can-drop-custom")) {
                    dragElementIcon[0].classList.remove("e-can-drop-custom");
                }
                dragElementIcon[0].classList.add("e-can-not-drop-custom");
            }
        }
    };

    const onUnbreak = async (data) => {
        const formatType = "HH:mm";
        let confirm = await Alert.Swal_confirm(t('SchedulerComponent-CONFIRM'),
            `${t('SchedulerComponent-ARE_YOU_SURE_YOU_WANT_TO_UNBREAK_FROM')} ${moment(data.startDate).format(formatType)} ${t('SchedulerComponent-TO')} ${moment(data.endDate).format(formatType)}?`, 3);
        if (_.get(confirm, "value") === true) {
            const response = await AppUtil.postApi("/timesheet/un-break", { technicianWorkingId: data.id });
            if (_.get(response, "data.success")) {
                onResetDataTechnician();
                AppUtil.ToastSuccess()
            } else {
                AppUtil.ToastApiError(t(_.get(response, "data.message")));
            }
        }

    }

    // const scrollToTechnician = (groupIndex, date) => {
    //     const elementWrap = document.getElementsByClassName("e-content-wrap");
    //     if (elementWrap[0]) {
    //         const element = elementWrap[0].querySelectorAll(`[data-group-index=${groupIndex.toString()}]`);
    //         console.log('Function: scrollToTechnician -> element: ', element);
    //     }
    //     console.log('Function: scrollToResource -> elementWrap: ', elementWrap);
    // }
    //render
    /*const templateRemind = () => {
        const eventCollection = getBookingSchedule();
        const format = "YYYY-MM-DD HH:mm";
        const hourRemind = moment().add(-180, "minutes").format(format)
        let template = null
        let condition = true
        _.each(eventCollection, event => {
            const startTimeEvent = moment(event.StartTime).format(format)
            if (condition && event.Status === BookingStatusContant.STATUS_WAITING) {
                if (_.indexOf(refHaveShowReminder.current, event.Id) < 0
                    && startTimeEvent >= hourRemind
                    && startTimeEvent <= moment().format(format)
                ) {
                    refHaveShowReminder.current.push(event.Id)
                    condition = false
                    const schedule = getTechnicanSchedule()
                    const technician = _.find(schedule, (x) => x.id === event.TechnicianId);
                    const groupIndex = _.findIndex(schedule, (x) => x.id === event.TechnicianId);
                    template = <div className={"e-toast-template"}>
                        <div className={"e-toast-message"}>
                            <div className={"e-toast-title"}>
                                {technician.name}: {event.Subject}
                            </div>
                            <div className={"e-toast-content"}>
                                <div className={"time"} onClick={() => alert("123")}>
                                    {moment(event.StartTime).format("HH:mm A")} - {moment(event.EndTime).format("HH:mm A")}
                                </div>
                                <div
                                    className={"go-button link-underline"}
                                    onClick={() => scrollToTechnician(groupIndex)}>
                                    Go to >>>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            }

        })
        return template;
    }*/


    const renderTitleForm = (text) => {
        return (
            <span
                style={{
                    position: "relative",
                    marginRight: 7,
                    display: "flex",
                    alignItems: "center"
                }}>
                <Icon type="form" style={{ fontSize: 16, display: "flex" }} />
                <span
                    style={{
                        fontSize: 15,
                        display: "flex",
                        marginLeft: 10
                    }}>
                    {t(text)}
                </span>
            </span>
        );
    }

    const renderOverlay = () => {
        return (
            <Overlay
                ref={refOverlay}
                style={{
                    borderRadius: 3,
                    zIndex: 99
                }}
            />
        );
    };

    const majorSlotTemplate = (props) => {
        const { date } = props;
        const format = "HH:mm";
        const hourFormat = moment(date).format("hh:mm A");
        const dataBooking = getBookingSchedule()
        const endMajor = moment(date).add(15, "m").format(format);

        // const startMajor = moment(date).format(format);
        const rs = [];
        if (!_.isEmpty(dataBooking)) {
            _.each(dataBooking, (item) => {
                if (item.Type !== "booking" && item.Status !== BookingStatusContant.STATUS_NEW && item.StartTime && item.EndTime && item.TechnicianId) {
                    const startItem = moment(item.StartTime).format(format);
                    const endItem = moment(item.EndTime).format(format);
                    //
                    // if ((startItem >= startMajor && startItem < endMajor) || (startMajor >= startItem && startMajor < endItem)) {
                    //     rs.push(item);
                    // }
                    if (startItem <= endMajor && endItem >= endMajor) {
                        rs.push(item);
                    }
                }
            });
            const count = _.size(_.uniqBy(rs, "GuestId"));
            if (count !== tempCount) {
                tempCount = count;
                return (
                    <div className="custom-major-lmt">
                        <span className="hour-format">{hourFormat}</span>
                        <span className="count">{count}</span>
                    </div>
                );
            }
        }
        return (
            <div className="custom-major-lmt">
                <span className="hour-format">{hourFormat}</span>
                {moment(date).format("HH:mm") === scheduleObj.current.startHour && <span className="count">{0}</span>}
            </div>
        );
    };

    const renderMouseTooltip = () => {
        return <MouseTooltipCpn ref={refMouseTooltipCpn} />;
    };

    const renderModal = () => {
        return <Modal ref={refModal} onClose={onCloseDefault} maskClosable={false}
            className="popup-crud popup-container" />;
    };

    const renderBill = () => {
        return <PrintBillComponent ref={refCpnPrintBill} />;
    }

    const translateContext = () => {
        const newContextMenu = [];
        _.map(ContextMenu, (menuItem) => {
            if (!_.isUndefined(menuItem.items)) {
                const childMenu = _.map(menuItem.items, (child) => {
                    return {
                        ...child,
                        text: t(child.text)
                    }
                });
                newContextMenu.push({
                    ...menuItem,
                    text: t(menuItem.text),
                    items: childMenu
                })
            } else {
                newContextMenu.push({
                    ...menuItem,
                    text: t(menuItem.text)
                })
            }
        });

        return newContextMenu;
    }

    return useMemo(() => {
        return (
            <div className="schedule-control-section">
                <div className="col-lg-12 control-section">
                    <div className="control-wrapper drag-sample-wrapper">
                        <ClipboardAppointment
                            ref={refClipboard}
                            onTreeDragStop={onTreeDragStop}
                            onTreeDragStart={onTreeDragStart}
                            onItemDrag={onItemDrag}
                            onUpdateBooking={onUpdateBooking}
                            onUpdateBookingNew={onUpdateBookingNew}
                            openOverlay={onOpenOverlay}
                        />

                        <div ref={refContainerWrap}
                            className={`schedule-container multiSetting`}>

                            <ScheduleComponent
                                ref={scheduleObj}
                                allowResizing={true}
                                allowMultiDrag={false}
                                resizeStop={onResizeStop}
                                resizeStart={onResizeStart}
                                allowMultiCellSelection={true}
                                cellTemplate={(args) => {
                                    if (args.type === "workCells") {
                                        return <CellTemplate args={args} onUnbreak={onUnbreak} />
                                    }
                                    return ""
                                }}
                                showHeaderBar={false}
                                showTimeIndicator={true}
                                timeScale={{
                                    enable: true,
                                    interval: 15,
                                    slotCount: 1,
                                    majorSlotTemplate: (args) => <MajorSlotTemplate date={args.date} />
                                }}
                                width="100%"
                                height={height}
                                selectedDate={date}
                                eventSettings={{
                                    dataSource: extend([], bookingSchedule, null, true),
                                    enableTooltip: true,
                                    tooltipTemplate: (args) => AppointmentRenderUtil.tooltipTemplate(args),
                                    enableMaxHeight: false
                                }}
                                group={{
                                    resources: ["Technicians"],
                                    enableCompactView: false
                                }}
                                currentView="Day"
                                showQuickInfo={false}
                                created={onCreated}
                                dataBound={onDataBinding}
                                renderCell={(args) => AppointmentRenderUtil.onRenderCell(args, toggleMouseTooltip, onClickMouse)}
                                eventRendered={(args) => AppointmentRenderUtil.onEventRendered(args, getBookingSchedule, refConfigAppointment)}
                                popupOpen={onPopupOpen}
                                actionComplete={onActionComplete}
                                actionBegin={onActionBegin}
                                drag={onItemDragDB}
                                dragStart={onDragStart}
                                dragStop={onDragStop}
                                cellClick={onCellClick}
                                cellDoubleClick={onCellDoubleClick}
                                allowDragAndDrop={true}
                                resourceHeaderTemplate={(args) => <ResourceHeaderTemplate
                                    resourceData={args.resourceData} />}
                                eventClick={(args) => eventClick(args.event)}
                            >

                                <ResourcesDirective>
                                    <ResourceDirective
                                        name="Technicians"
                                        field="TechnicianId"
                                        title="Choose"
                                        allowMultiple={true}
                                        dataSource={technicianSchedule}
                                        textField="name"
                                        idField="id"
                                        startHourField="startHour"
                                        endHourField="endHour" />
                                </ResourcesDirective>
                                <ViewsDirective>
                                    <ViewDirective allowVirtualScrolling={true} option={"Day"}
                                        // eventTemplate={(args) => AppointmentRenderUtil.eventTemplate(args, waitingList, getBookingSchedule, openDetailCustomer,)}
                                        eventTemplate={(args) => <EventTemplate args={args}
                                            openDetailCustomer={openDetailCustomer} />}
                                    />
                                </ViewsDirective>
                                <Inject services={[Day, Resize, DragAndDrop]} />
                            </ScheduleComponent>
                        </div>

                    </div>
                    <ContextMenuComponent cssClass="schedule-context-menu"
                        ref={refMenuContext} target=".e-schedule"
                        items={translateContext()}
                        beforeOpen={onContextMenuBeforeOpen}
                        beforeClose={onContextMenuBeforeClose}
                        select={onMenuItemSelect}

                    />

                </div>
                {renderMouseTooltip()}
                {renderModal()}
                {renderBill()}
            </div>

        );
    }, []);

});
export default SchedulerComponent;
