import React, { useMemo } from 'react';
import { useAppointmentContext } from "../context/AppointmentContext";
import _ from "lodash";
import Icon from "@idtek/component/lib/icon/Icon";
import { BookingStatusContant } from "../constant/BookingStatusContant";
import AppUtil from "../../../utils/AppUtil";
import moment from "moment";
import '../style/event-template.scss'
import Badge from '@idtek/component/lib/badge/Badge'

const EventTemplate = ({ args, openDetailCustomer }) => {
    const {
        Subject,
        StartTime,
        EndTime,
        CustomerName,
        Phone,
        Status,
        PaymentMethod,
        Block,
        Color,
        TypeBooking,
        Description,
        Confirm,
        CustomerId,
        BookingId,
        Id,
        GuestId,
        Gender,
        IsCustomerNew,
        CustomerBirthday,
        Date
    } = args
    const {
        dataBooking, waitingList
    } = useAppointmentContext();

    return useMemo(() => {
        let warning = <></>;
        if (_.find(waitingList, (x) => x.Id === BookingId)) {
            warning = <Icon type="exclamation-circle" className={"warning-icon"} />;
        }
        let block = <></>;
        if (Block) {
            block = <Icon type="lock" className={"lock-icon"} />;
        }
        let confirm = <></>;
        if (Confirm) {
            confirm = <Icon type="like" className={"like-icon"} />;
        }
        let genderIcon = <></>
        if (Gender === "MALE") {
            genderIcon = <Icon type="man" className={"gender-man"} />;
        } else if (Gender === "FEMALE") {
            genderIcon = <Icon type="woman" className={"gender-woman"} />;
        }

        const birthDayFixYear = moment(CustomerBirthday).year(moment(StartTime).year());

        let birthDay = StartTime && CustomerBirthday && moment(StartTime).isSame(birthDayFixYear, "day") ?
            <span className={"cake-icon-background"}><i className="fa fa-birthday-cake cake-icon"
                aria-hidden="true" /></span> : null;
        let progressIcon = <></>
        switch (Status) {
            case BookingStatusContant.STATUS_WAITING:
                progressIcon = <span className={"progress-circle progress-25"} />
                break;
            case BookingStatusContant.STATUS_CHECKIN:
                progressIcon = <span className={"progress-circle progress-50"} />
                break;
            case BookingStatusContant.STATUS_PROCESSING:
                progressIcon = <span className={"progress-circle progress-75"} />
                break;
            case BookingStatusContant.STATUS_COMPLETE:
                progressIcon = <span className={"progress-circle progress-100"} />
                break;
            default:
                console.log('Function: default EventTemplate-> : ');
        }

        const findSimilar = _.filter(dataBooking, (x) => x.GuestId === GuestId);
        const index = _.findIndex(findSimilar, (x) => x.Id === Id);
        const sizeSimilar = _.size(findSimilar);

        let groupIcon = <></>
        const findGroup = _.groupBy(_.filter(dataBooking, (x) => x.BookingId === BookingId), 'GuestId')
        if (_.size(findGroup) > 1) {
            groupIcon = <Icon type="team" style={{ color: Color, fontSize: 12 }} />;
        }

        return (<div className="template-wrap-lmt event-template">
            {PaymentMethod && Status === BookingStatusContant.STATUS_COMPLETE ? (<div>
                {IsCustomerNew &&
                    <Badge count={<Icon type="star" />} status="processing" text="" color={"#e04248"}
                        className={"warning-customer-new"} />}
                <div className="e-subject subject">

                    {TypeBooking === "TYPE_BOOKING_ONLINE" && <span className={"type-online"}>@</span>}
                    {Description && <Icon className={"icon-des"} type="info-circle" />}
                    {Subject}
                    {progressIcon}
                </div>
                <div className="e-customer link-underline" onClick={(e) => openDetailCustomer(e, CustomerId)}>
                    {birthDay}
                    {CustomerName}
                </div>
                <div className="e-phone">{AppUtil.formatPhone(Phone)}</div>
                <div
                    className="e-time time">{moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</div>
                <div className="e-icon">
                    <Icon className={"icon-check-green"}
                        type="check-circle" />
                    {groupIcon}
                    {sizeSimilar > 1 && (<div className="link">
                        <span className="number-link"
                            style={{ color: Color ? Color : "var(--main-color-red)" }}>
                            {index + 1}/{sizeSimilar}
                        </span>
                        <Icon type="link" style={{ color: Color ? Color : "red", fontSize: 13 }} />
                    </div>)}
                </div>
            </div>) : (<div>
                {IsCustomerNew && <Icon type="star" category="TwoTone" twoToneColor={"#faa633"}
                    className={"warning-customer-new"} />}
                <div className="e-subject subject" style={{ marginLeft: IsCustomerNew ? 11 : 0 }}>
                    {TypeBooking === "TYPE_BOOKING_ONLINE" && <span className={"type-online"}>@</span>}
                    {Description && <Icon className={"icon-des"} type="info-circle" />}
                    {Subject}
                    {progressIcon}
                </div>

                <div>
                    {birthDay}
                    <span className="e-customer link-underline"
                        onClick={(e) => openDetailCustomer(e, CustomerId)}>
                        {CustomerName}
                    </span>

                </div>

                <div className="e-phone">{AppUtil.formatPhone(Phone)}</div>
                <div
                    className="e-time time">{moment(StartTime).format("hh:mm A") + " - " + moment(EndTime).format("hh:mm A")}</div>
                <div className="e-icon">
                    {warning}
                    {block}
                    {confirm}
                    {groupIcon}
                    {genderIcon}
                    {sizeSimilar > 1 && (<div className="link">
                        <span className="number-link"
                            style={{ color: Color ? Color : "var(--main-color-red)" }}>
                            {index + 1}/{sizeSimilar}
                        </span>
                        <Icon type="link" style={{ color: Color ? Color : "red", fontSize: 12 }} />
                    </div>)}
                </div>
            </div>)}

        </div>);
    }, [dataBooking, waitingList])

};

export default EventTemplate;