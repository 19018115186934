import React, { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from "@idtek/component/lib/tooltip/Tooltip";
import { Badge, Icon } from "@idtek/component";
import _ from "lodash";
import ButtonCollapse from "./ButtonCollapse";
import SelectCustomComponent from "./SelectCustomComponent";
import ReactDatePickerCustom from "../../../common/component/ReactDatePickerCustom";
import { useAppointmentContext } from "../context/AppointmentContext";
import moment from "moment";
import { t } from "@idtek/component/lib/language";
import AppUtil from "../../../utils/AppUtil";

const FilterHeaderLeft = ({ branchId, authData, onClickUpdateBooking, onChange, date }) => {
    const [branch, setBranch] = useState(branchId);
    const [filterDate, setFilterDate] = useState(date ?? moment().format("YYYY-MM-DD"));
    const btnCollapse = useRef(null);
    const { enableDock, setEnableDock, waitingList } = useAppointmentContext();

    useEffect(() => {
        setBranch(branchId);
    }, [branchId]);

    useEffect(() => {
        btnCollapse.current && btnCollapse.current.setOpen(enableDock);
    }, [enableDock])


    const onActionButton = (key) => {
        let date = moment().format("YYYY-MM-DD");
        let scrollToCurrentHour = false;
        switch (key) {
            case "prev":
                date = moment(filterDate).add(-1, "days").format("YYYY-MM-DD");
                break;
            case "next":
                date = moment(filterDate).add(1, "days").format("YYYY-MM-DD");
                break;
            case "today":
                date = moment().format("YYYY-MM-DD");
                scrollToCurrentHour = true;
                break;
            default:
                date = moment().format("YYYY-MM-DD");
                break;
        }
        loadData(date, scrollToCurrentHour);
    };

    const loadData = (date, scrollToCurrentHour) => {
        const newDate = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
        onChange && onChange({ dateChange: newDate, scrollToCurrentHour });
        setFilterDate(newDate);
    };

    return useMemo(() => {
        return (
            <div className="filter-left">
                <Tooltip title={t("FilterHeaderLeft-CLIPBOARD")} placement={"leftTop"}>
                    <Badge size="small" count={_.size(waitingList)} offset={[-12, 0]}>
                        <ButtonCollapse ref={btnCollapse} onClick={() => setEnableDock(!enableDock)} />
                    </Badge>
                </Tooltip>
                <div style={{ flex: 1, width: 250 }}>
                    <SelectCustomComponent
                        onChange={(data) => {
                            onClickUpdateBooking && onClickUpdateBooking(data);
                        }}
                        branch={branch}
                        date={filterDate}
                    />
                </div>
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                    <div className="today" style={{ marginLeft: 12 }}>
                        <button onClick={() => onActionButton("today")} className="btn-date-filer today-date">
                            {t("FilterHeaderLeft-TODAY")}
                        </button>
                    </div>
                    <div className="date-cpn-filter">
                        <Icon type="left" className="prev" onClick={() => onActionButton("prev")} />
                        <ReactDatePickerCustom value={filterDate} onChange={loadData}
                            dateFormat={AppUtil.GLOBAL_DATE_FORMAT} />
                        <Icon type="right" className="next" onClick={() => onActionButton("next")} />
                    </div>
                </div>
            </div>
        );
    }, [branch, filterDate, enableDock, waitingList])

};

export default FilterHeaderLeft;